@import "./_variables.scss";

// Specific breakpoints
@media (min-width: $small-0) {
  .marginBot {
    margin-bottom: 10px;
  }
  .boxInfo {
    margin-right: 0px;
  }
}

@media (min-width: $extra-small-screen) and (max-width: 618px) {
  .boxInfo {
    width: 75%;
  }
  .marginBot {
    margin-bottom: 10px;
  }
}

// default breakpoints
@media (max-width: $extra-small-screen) {
  .boxInfo {
    width: 60%;
  }
}

@media (max-width: $small-screen) {
  .sidebar {
    left: -250px; /* Hide sidebar on small screens */
  }

  .sidebar.expanded {
    left: 0;
  }

  .desktop-nav {
    display: none;
  }

  .content {
    margin-left: 0 !important;
  }

  .mobile-nav {
    display: flex;
  }
  .nav {
    display: block;
  }
}

@media (max-width: $medium-screen) {
  .desktop-nav {
    display: none;
  }
  .content {
    margin-left: 0px !important;
  }
  .mobile-nav {
    display: flex !important;
  }
}

@media (max-width: $large-screen) {
}
