.dark-mode {
  background-color: black;
  color: cyan;
}

.dark-mode .pointerMouse {
  cursor: url("../imgs/cursor2.png"), pointer !important;
}

.dark-mode .sidebar {
  background-color: black;
  color: cyan;
  box-shadow: inset -2px 0 0 white;
}

.dark-mode .nav-link {
  color: cyan !important;
}

.dark-mode li {
  color: cyan;
}

.dark-mode .navbar {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27cyan%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: cyan;
}

.dark-mode .btnOwn {
  background-color: black;
  border-color: cyan;
  color: cyan;
}

.dark-mode .table-light {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
