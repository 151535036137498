@import "./styles/_variables.scss";
@import "./styles/responsive.scss";
@import "./styles/dark.scss";

* {
  font-family: $font-family;
}

body {
  cursor: url("./imgs/cursor1.png"), auto;
}

// Global variables
.boxInfo {
  border: 2px solid lightgray;
  border-radius: 10px;
}

.pointerMouse {
  cursor: url("./imgs/cursor2.png"), pointer !important;
}

.btnOwn {
  background-color: black;
  border-color: white;
  color: white;
}

ul {
  list-style-type: none;
}

.txt-md {
  font-size: 1.1rem;
}

// Navbar
.mobile-nav {
  display: none;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 48px 0;
  box-shadow: inset -2px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover {
  color: #007bff;
  text-decoration: none;
}

.content {
  margin-left: 240px;
}

.clickable-flag {
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
}

.navbar {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%2833, 37, 41, 0.75%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
  margin-left: 10px;
}

// dark mode button
.toggle-button {
  width: 50px;
  height: 30px;
  border-radius: 25px;
  position: relative;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.2s;
}

.toggle-button:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: #333;
  transition: left 0.2s;
}

.toggle-button.dark {
  background-color: white;
}

.toggle-button.dark:before {
  left: 25px;
}

//Home
.mt-profile {
  margin-top: 8%;
}

// Projects
